<template>
  <div class="calview">
    <table>
      <thead>
      <tr>
        <th v-for="day in days" :key="day">{{ day }}</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(date, idx) in dates" :key="idx">
        <td :id="`D_${day}`"
            v-for="(day, secondIdx) in date" :key="secondIdx"
        >
          <template v-if="day !== null">
            <p :class="['cal_date', {'cal_date_today':isToDay(day)}]">{{ day }}</p>
            <div class="day_sch"
                 v-for="webinar in webinarList[`${year}${String(month).padStart(2, '0')}${String(day).padStart(2, '0')}`]"
                 :key="webinar.link">
              <a :href="`${path}/${webinar.link}`">
                <p class="cal_less_info">[{{ webinar.startTime }}] {{ webinar.prof }}</p>
                <p class="cal_tit">{{ webinar.title }}</p>
                <button type="button" :class="`btn_${webinar.linkType}`"
                        @click.prevent.stop="webinarRegister(webinar.linkType, {
                          id: webinar.link,
                          title: webinar.title,
                          prof: webinar.prof,
                          date: webinar.date,
                        })">{{ linkTypeName[webinar.linkType] }}
                </button>
              </a>
            </div>
          </template>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "CalendarView",
  data() {
    return {
      days: [
        '월',
        '화',
        '수',
        '목',
        '금',
      ],
      dates: [],
      currentYear: 0,
      currentMonth: 0,
      year: this.selectYear,
      month: this.selectMonth,
      lastMonthStart: 0,
      nextMonthStart: 0,
      today: 0,
      path: location.pathname,
      linkTypeName: {
        'fini': '웨비나 종료',
        'join': '참여하기',
        'comp': '등록완료',
        'regi': '사전등록',
        'replay': '다시보기',
      },
    }
  },
  props: {
    selectYear: {
      type: [Number, String],
      default: new Date().getFullYear(),
    },
    selectMonth: {
      type: [Number, String],
      default: new Date().getMonth() + 1,
    },
    webinarList: {
      type: Object,
    }
  },
  watch: {
    selectYear() {
      this.year = this.selectYear
      this.calendarData();
    },
    selectMonth() {
      this.month = this.selectMonth
      this.calendarData();
    }
  },
  created() { // 데이터에 접근이 가능한 첫 번째 라이프 사이클
    const date = new Date();
    this.currentYear = date.getFullYear(); // 이하 현재 년, 월 가지고 있기
    this.currentMonth = date.getMonth() + 1;
    // this.year = this.selectYear;
    // this.month = this.selectMonth;
    this.today = date.getDate(); // 오늘 날짜
    this.calendarData();
  },
  methods: {
    webinarRegister(type, webinar) {
      if (type === 'regi') {
        this.$emit('webinarRegister', webinar)
      } else if (type === 'join') {
        this.$emit('webinarJoin', webinar)
      } else {
        window.location.href = `${this.path}/${webinar.id}`
      }
    },
    isToDay(day) {
      return this.currentYear === this.selectYear && this.currentMonth === this.selectMonth && this.today === day;
    },
    calendarData(arg) { // 인자를 추가
      if (arg < 0) { // -1이 들어오면 지난 달 달력으로 이동
        this.month -= 1;
      } else if (arg === 1) { // 1이 들어오면 다음 달 달력으로 이동
        this.month += 1;
      }
      if (this.month === 0) { // 작년 12월
        this.year -= 1;
        this.month = 12;
      } else if (this.month > 12) { // 내년 1월
        this.year += 1;
        this.month = 1;
      }
      const [
        monthFirstDay,
        monthLastDate,
      ] = this.getFirstDayLastDate(this.year, this.month);
      this.dates = this.getMonthOfDays(
          monthFirstDay,
          monthLastDate,
      );
    },
    getFirstDayLastDate(year, month) {
      const firstDay = new Date(year, month - 1, 1).getDay(); // 이번 달 시작 요일
      const lastDate = new Date(year, month, 0).getDate(); // 이번 달 마지막 날짜
      return [firstDay, lastDate];
    },
    ignoreSunSat(year, month, day) {
      return new Date(year, month - 1, day).getDay() !== 0 && new Date(year, month - 1, day).getDay() !== 6
    },
    getMonthOfDays(
        monthFirstDay,
        monthLastDate,
    ) {
      let day = 1;
      const dates = [];
      let weekOfDays = [];
      while (day <= monthLastDate) {
        if (day === 1) {
          for (let j = 0; j < monthFirstDay - 1; j++) {
            weekOfDays.push(null);
          }
        }
        if (this.ignoreSunSat(this.year, this.month, day)) {
          weekOfDays.push(day);
        }
        if (weekOfDays.length === 5) {
          // 일주일 채우면
          dates.push(weekOfDays);
          weekOfDays = []; // 초기화
        }
        day++;
      }

      if (weekOfDays.length > 0) {
        dates.push(Array(5).fill(null).map((value, index) => {
          return weekOfDays[index] ?? value
        }));
      }
      return dates;
    },
  },
}
</script>

<style scoped>

</style>
