<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <div class="top-level-page-area top-level-page-area-05">
      <div class="bms-relaunch-comp-v2">
        <h1 class="page-header">웨비나 라이브</h1>
        <div class="feature-body">
          <p>BMS에서 주최하는 질환별, 제품별 웨비나 라이브 일정을 조회하고, 사전 등록하실 수 있는 서비스입니다.</p>
        </div>
      </div>
    </div>
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb"><a href="#">웨비나 & VOD</a></li>
            <li class="breadcrumb">웨비나 라이브</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->

      <div class="section">
        <div class="less-sch sch-card">
          <div class="month_top">
            <div class="select_area">
              <select name="sch_year" class="sel_cal" v-model="selectYear" @change="reload({
                ...getThisMonth(selectYear, selectMonth),
                ...selectTherapeuticArea && {'ta': selectTherapeuticArea},
                ...selectProduct && {'product': selectProduct},
              })">
                <option v-for="year in 7"
                        :value="new Date().getFullYear() - year + 4" v-text="new Date().getFullYear() - year + 4"
                        :key="new Date().getFullYear() - year + 4"/>
              </select>
              <select name="sch_month" class="sel_cal" v-model="selectMonth" @change="reload({
                  ...getThisMonth(selectYear, selectMonth),
                  ...selectTherapeuticArea && {'ta': selectTherapeuticArea},
                  ...selectProduct && {'product': selectProduct},
                })">
                <option v-for="index in 12" :value="index" :key="index">{{ String(index).padStart(2, '0') }}</option>
              </select>
            </div>
            <div class="month_tit">
              <button type="button" class="btn_prev" @click="calendarData(-1)"><span>이전</span></button>
              <span>{{ selectMonth }}월 웨비나</span>
              <button type="button" class="btn_next" @click="calendarData(1)"><span>다음</span></button>
            </div>
            <div class="icon_area">
              <a role="link" @click="switchCalView">
                <img src="@/assets/images/icon_calview.png"
                     alt="" v-if="!isCalView">
                <img src="@/assets/images/icon_cardview.png"
                     alt="" v-else>
              </a>
            </div>
          </div>
          <div class="select_area">
            <select name="sch_arear" class="sel_cal" v-model="selectTherapeuticArea" @change="reload({
              ...getThisMonth(selectYear, selectMonth),
              ...selectTherapeuticArea && {'ta': selectTherapeuticArea},
            })">
              <option value="">TherapeuticArea</option>
              <option v-for="(menu, index) in therapeuticArea" :key="index"
                      :value="menu.value" v-html="menu.name"/>
            </select>
            <select name="sch_prod" class="sel_cal" v-model="selectProduct" @change="reload({
              ...getThisMonth(selectYear, selectMonth),
              ...selectTherapeuticArea && {'ta': selectTherapeuticArea},
              ...selectProduct && {'product': selectProduct},
            })">
              <option value="">Product</option>
              <option v-for="(product, index) in productList[selectTherapeuticArea]" :key="index"
                      :value="product.value" v-html="product.name"/>
            </select>
          </div>
          <calendar-view
              :select-year="selectYear"
              :select-month="selectMonth"
              :webinarList="webinarCalendarList"
              v-if="isCalView"
              @webinarRegister="webinarRegister"
              @webinarJoin="webinarJoin"
          />
          <div class="cardview" v-else>
            <!--카드 s-->
            <webinar-card-list-view
                v-for="(webinar , index) in webinarList"
                :key="index"
                v-bind="webinar"
                @webinarRegister="webinarRegister"
                @webinarJoin="webinarJoin"
            />
            <!--카드 e-->
          </div>
        </div>
        <!--more버튼-->
        <!--        <div class="btn_area btn_more" v-if="!isCalView">-->
        <!--          <button>Load More</button>-->
        <!--        </div>-->
        <!--  Modal -->
        <div class="modal" v-if="showSubmitModal">

          <!--  Modal의 내용 -->
          <div class="modal_contents">
            <div class="modal_close close">
              <a @click="closeModal">close</a>
            </div>
            <p class="modal_tit">감사합니다!</p>
            <div class="modal_exp">
              <ul>
                <li>강의명 : {{ modalTitle }}</li>
                <li>강사명 : {{ modalProf }}</li>
                <li>강의일시 : {{ modalDate }}</li>
              </ul>
              <span>상기 웨비나 라이브 <b>사전등록</b>이 완료되었습니다.</span>
              <p>* 웨비나 라이브 1일전에 해당 웨비나 안내 메일을 수신하실 수 있습니다.</p>
              <p>* 본 사전등록 내역은 본 웹사이트의 Mypage에서도 확인하 실 수 있습니다.</p>
            </div>
            <div class="modal_btn">
              <span class="btn_modal" @click="closeModal">확인</span>
            </div>
          </div>
        </div>
        <div class="modal" v-if="showPostWebinar">

          <!-- 파이프라인 사이트 이탈 Modal의 내용 -->
          <div class="modal_contents">
            <div class="modal_close close">
              <a @click="closeSubmitModal">close</a>
            </div>
            <p class="modal_tit">현재 보고 계신<br> <strong>BMSON</strong> 웹사이트를 나갑니다.</p>
            <p class="modal_exp">현재 웹사이트를 나와 다른 외부 웹사이트와 연결됩니다. BMSON은 외부 사이트의 콘텐츠에 대한 책임을 지지 않으며, 해당 콘텐츠로 인한 손해나 손상에
              대한 법적 책임이 없습니다. 외부 사이트 링크는 단지 웹사이트 사용자들에게 편의를 제공하기 위함입니다.</p>
            <div class="modal_btn">
              <form @submit="closeSubmitModal" :action="webinarUrl"
                    method="post" target="_blank" id="form_in_modal">
                <input type="hidden" name="member" :value="encodeMember">
                <!--                <button type="submit"></button>-->
              </form>
              <span class="btn_modal" @click="submitModal">계속하기</span>
              <span class="btn_modal" @click="closeSubmitModal">취소</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import CalendarView from "@/components/Webinar/CalendarView";
import WebinarCardListView from "@/components/Webinar/WebinarCardListView";
import webinarAPI from "@/api/webinarAPI";


export default {
  name: "WebinarLiveList",
  components: {WebinarCardListView, CalendarView},
  computed: {},
  mounted() {
    if(this.$route.query['open-modal']) {
      this.$nextTick(() => {
        this.switchModal()
      })
    }
  },
  watch: {
    isCalView() {
      location.hash = this.isCalView ? '1' : '2'
    },
    selectTherapeuticArea() {
      this.selectProduct = ''
    }
  },
  data() {
    return {
      showSubmitModal: false,
      modalTitle: '',
      modalProf: '',
      modalDate: '',
      webinarList: [],
      webinarCalendarList: {},
      selectYear: new Date().getFullYear(),
      selectMonth: new Date().getMonth() + 1,
      isCalView: location.hash === '#1' || location.hash !== '', //기본값 : false
      originIsCalView: location.hash === '#1' || location.hash !== '', //기본값 : false
      isMobileView: false,
      selectTherapeuticArea: '',
      selectProduct: '',
      showPostWebinar : false,
      encodeMember : '',
      webinarUrl : '',
      therapeuticArea: [
        {
          name: 'Hematology',
          value: 1
        },
        {
          name: 'Oncology',
          value: 2
        },
        {
          name: 'Immunology',
          value: 3
        },
        {
          name: 'Virology',
          value: 4
        },
        {
          name: 'Cardiology',
          value: 5
        },
      ],
      productList: {
        1: [
          {
            name: 'INREBIC<sup>®</sup> <span>(fedratinib)</span>',
            value: 'inrebic'
          },
          {
            name: 'ONUREG<sup>®</sup> <span>(azacitidine)</span>',
            value: 'onureg'
          },
          {
            name: 'REBLOZYL<sup>®</sup> <span>(luspatercept-aamt)</span>',
            value: 'reblozyl'
          },
          {
            name: 'REVLIMID<sup>®</sup> <span>(lenalidomide)</span> / POMALYST<sup>®</sup> <span>(pomalidomide)</span>',
            value: 'revlimid-pomalyst'
          },
          {
            name: 'SPRYCEL<sup>®</sup> <span>(dasatinib)</span>',
            value: 'sprycel'
          },
          {
            name: 'BMS THALIDOMIDE<sup>™</sup>(thalidomide) ',
            value: 'thalidomide-celgene'
          },
          {
            name: 'VIDAZA<sup>®</sup> <span>(azacitidine)</span>',
            value: 'vidaza'
          },
        ],
        2: [
          {
            name: 'OPDIVO<sup>®</sup> <span>(nivolumab)</span> / YERVOY<sup>®</sup> <span>(ipilimumab)</span>',
            value: 'opdivo-yervoy',
          },
          {
            name: 'ABRAXANE<sup>®</sup> <span>(paclitaxel protein-bound particles for injectable suspension) (albumin-bound)</span>',
            value: 'abraxane',
          },
        ],
        3: [
          {
            name: 'ORENCIA<sup>®</sup> <span>(abatacept)</span>',
            value: 'orencia',
          },
        ],
        4: [
          {
            name: 'BARACLUDE<sup>®</sup> <span>(entecavir)</span>',
            value: 'Virology'
          }
        ],
        5: [
          {
            name: 'ELIQUIS<sup>®</sup> <span>(apixaban)</span>',
            value: 'eliquis',
          },
        ],
      },
    }
  },
  created() {
    this.reload({
      ...this.getThisMonth(this.selectYear, this.selectMonth),
      ...this.selectTherapeuticArea && {'ta': this.selectTherapeuticArea},
      ...this.selectProduct && {'product': this.selectProduct},
    })
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    submitModal() {
      document.getElementById('form_in_modal').submit();
      setTimeout(() => {
        document.documentElement.classList.remove("overflow-y-hidden");
        this.showPostWebinar = false;
        this.showSubmitModal = false;
      })
      return true;
    },
    closeSubmitModal() {
      setTimeout(() => {
        document.documentElement.classList.remove("overflow-y-hidden");
        this.showPostWebinar = false;
        this.showSubmitModal = false;
      })
      return true
    },
    switchModal() {
      this.showSubmitModal = !this.showSubmitModal;
      if (this.showSubmitModal) {
        document.documentElement.classList.add("overflow-y-hidden");
      } else {
        document.documentElement.classList.remove("overflow-y-hidden");
      }
    },
    switchCalView() {
      this.isCalView = !this.isCalView
      this.originIsCalView = this.isCalView
    },
    handleResize() {
      if (window.innerWidth <= 1023) {
        if (!this.isMobileView) {
          this.originIsCalView = this.isCalView
          this.isCalView = false
        }
        this.isMobileView = true
      } else {
        this.isMobileView = false
        this.isCalView = this.originIsCalView
      }
    },
    closeModal() {
      this.switchModal()
    },
    async webinarRegister(webinar) {
      await webinarAPI.postWebinarRegister(webinar.id).then(value => {
        const {data} = value
        if (data.code === 200) {
          this.modalTitle = webinar.title;
          this.modalProf = webinar.prof;
          this.modalDate = webinar.date;
          this.switchModal()
          this.reload({
            ...this.getThisMonth(this.selectYear, this.selectMonth),
            ...this.selectTherapeuticArea && {'ta': this.selectTherapeuticArea},
            ...this.selectProduct && {'product': this.selectProduct},
          })
        }
      }).catch(e => {
        console.log(e)
        throw e
      })
    },
    async webinarJoin(webinar) {
      this.$store.dispatch('fetchWebinarNotPoint', {id: webinar.id})
          .then(value => {
            let {
              webinarUrl,
            } = value
            this.webinarUrl = webinarUrl
            webinarAPI.postWebinarUserInfo().then(result => {
              let {data: {value}} = result
              webinarAPI.savePointWebinar({
                id: webinar.id
              }).then()
              this.encodeMember = value
              this.showPostWebinar = true

              document.documentElement.classList.add("overflow-y-hidden");
            })
          })

    },
    reload(payload) {
      this.fetchWebinarList(payload)
      this.fetchWebinarCalendarList(payload)
    },
    fetchWebinarList(payload) {
      this.$store.dispatch('initWebinarList', {
        query: {
          ...payload
        }
      }).then(webinarList => {
        this.webinarList = webinarList
      })
    },
    fetchWebinarCalendarList(payload) {
      this.$store.dispatch('initWebinarCalendarList', {
        query: {
          ...payload
        }
      }).then(webinarCalendarList => {
        this.webinarCalendarList = webinarCalendarList
      })
    },
    calendarData(arg) { // 인자를 추가
      if (arg < 0) { // -1이 들어오면 지난 달 달력으로 이동
        this.selectMonth -= 1;
      } else if (arg === 1) { // 1이 들어오면 다음 달 달력으로 이동
        this.selectMonth += 1;
      }
      if (this.selectMonth === 0) { // 작년 12월
        this.selectYear -= 1;
        this.selectMonth = 12;
      } else if (this.selectMonth > 12) { // 내년 1월
        this.selectYear += 1;
        this.selectMonth = 1;
      }
      this.reload({
        ...this.getThisMonth(this.selectYear, this.selectMonth),
        ...this.selectTherapeuticArea && {'ta': this.selectTherapeuticArea},
        ...this.selectProduct && {'product': this.selectProduct},
      })
    },
    getThisMonth(year, month) {
      let now = new Date(year, month - 1, 1);
      let nowYear = now.getYear();
      let firstDate, lastDate;

      let formatDate = function (date) {
        let myMonth = date.getMonth() + 1;
        let myWeekDay = date.getDate();

        let addZero = function (num) {
          if (num < 10) {
            num = "0" + num;
          }
          return num;
        };
        return `${addZero(myMonth)}-${addZero(myWeekDay)}`;
      };

      firstDate = new Date(now.getYear(), now.getMonth(), 1);
      lastDate = new Date(now.getYear(), now.getMonth() + 1, 0);
      nowYear += (nowYear < 2000) ? 1900 : 0;
      return {
        startDate: `${nowYear}-${formatDate(firstDate)}`,
        endDate: `${nowYear}-${formatDate(lastDate)}`
      };
    },
  },
}
</script>

<style scoped>

</style>
